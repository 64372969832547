import React, { useEffect, useState } from 'react';
import { BsClipboardData, BsMap } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import CardEvent from '../../components/CardEvent/CardEvent';
import Maps from '../../components/Maps/Maps';
import Modal from '../../components/Modal/Modal';
import ModalEmployee from '../../components/ModalEmployee/ModalEmployee';
import Sidebar from '../../components/Sidebar/Sidebar';
import { SERVER_URL } from '../../helpers/const';
import { roles } from '../../navigation/navigation';
import {
  getCheckings,
  ICheckingConf,
} from '../../store/slices/checkingSlice/checkingSlice';
import { getEmployee, getEmployees } from '../../store/slices/employeeSlice/employeeSlice';
import { getEvents } from '../../store/slices/eventSlice/eventSlice';
import {
  getObject,
  getObjects,
} from '../../store/slices/objectSlice/objectSlice';
import { getShifts } from '../../store/slices/shiftSlice/shiftSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';

const EmployeeViewPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const employee = useAppSelector((state) => state.employee.currentEmployee);
  const checking = useAppSelector((state) => state.checking.data);
  const shift = useAppSelector((state) => state.shift.data);
  const user = useAppSelector((state) => state.user.user);
  const events = useAppSelector((state) => state.event.data);
  const objects = useAppSelector((state) => state.object.data);
  const employees = useAppSelector((state) => state.employee.data);
  const dataRequested = useAppSelector((state) => state.shift.isRequested);
  const eventsRequested = useAppSelector((state) => state.event.isRequested);
  const objectsRequested = useAppSelector((state) => state.object.isRequested);
  const employeesRequested = useAppSelector((state) => state.employee.isRequested);
  const checkingsRequested = useAppSelector((state) => state.checking.isRequested);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [coords, setCoords] = useState([]);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const openModal2 = (item: ICheckingConf) => {
    setCoords(item.coords ? JSON.parse(item.coords) : []);
    setOpen2(true);
  };
  const closeModal2 = () => setOpen2(false);

  // useEffect(() => {
  //   dispatch(getObjects({ limit: 0, offset: 0 }));
  //   dispatch(getEmployee(id!));
  //   dispatch(
  //     getShifts({
  //       limit: 0,
  //       offset: 0,
  //       query_and: [{ input: 'guard_id', value: Number(id) }],
  //     })
  //   );
  //   dispatch(
  //     getCheckings({
  //       limit: 0,
  //       offset: 0,
  //       query_and: [{ input: 'guard_id', value: Number(id) }],
  //     })
  //   );
  //   dispatch(
  //     getEvents({
  //       limit: 0,
  //       offset: 0,
  //       query_and: [{ input: 'guard_id', value: Number(id) }],
  //     })
  //   );
  // }, []);


  useEffect(() => {
    if (!user) return;
    if (user.role === roles.client) {
      if (!objectsRequested) {
    dispatch(getEmployee(id!));
        dispatch(getObjects({ limit: 0, offset: 0, query_and: [{ input: 'client_id', value: user.id }] }));

      } else if (!eventsRequested && !checkingsRequested && !employeesRequested && !dataRequested) {

        dispatch(getShifts({ limit: 0, offset: 0, query: [...objects.map(object => ({ input: 'object_id', value: object.id })), ], query_and: [{ input: 'guard_id', value: Number(id!) }] }));

        dispatch(getEvents({ limit: 0, offset: 0, query: [...objects.map(object => ({ input: 'object_id', value: object.id })), ], query_and: [{ input: 'guard_id', value: Number(id!) }] }));

        dispatch(getCheckings({ limit: 0, offset: 0, query: [...objects.map(object => ({ input: 'object_id', value: object.id })), ], query_and: [{ input: 'guard_id', value: Number(id!) }] }));

        const securityIds: any = objects.map(object => {
          return JSON.parse(object.ohraniki).map((security: any) => security.value)
        });

        dispatch(getEmployees({ limit: 0, offset: 0, query: securityIds.flat().map((id: any) => ({ input: 'id', value: id })) }));
      }

    } else if (!eventsRequested && !checkingsRequested && !employeesRequested && !objectsRequested && !objectsRequested && !dataRequested) {
          dispatch(getEmployee(id!));
      dispatch(getEmployees({ limit: 0, offset: 0 }));
      dispatch(getObjects({ limit: 0, offset: 0 }));

      dispatch(
        getShifts({
          limit: 0,
          offset: 0,
          query_and: [{ input: 'guard_id', value: Number(id!) }],
        })
      );
      dispatch(
        getCheckings({
          limit: 0,
          offset: 0,
          query_and: [{ input: 'guard_id', value: Number(id!) }],
        })
      );

      dispatch(
        getEvents({
          limit: 0,
          offset: 0,
          query_and: [{ input: 'guard_id', value: Number(id!) }],
        })
      );
    }
  }, [dataRequested, user, objects, eventsRequested, checkingsRequested, employeesRequested, objectsRequested,]);


  const access = [roles.director, roles.headOfSecurity].includes(user.role);


  return (
    <div>
      <Sidebar />
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <h6 className='m-0'>Информация</h6>
          {
            access ? (<button className='btn btn-info btn-sm m-0' onClick={openModal}>
              Изменить
            </button>) : ''
          }
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card cursor mb-3'>
              <div className='card-header p-3 pt-2'>
                {
                  employee?.photo ? (<div className="border-radius-xl mt-n3" style={{ width: 80, height: 80, backgroundImage: `url(${SERVER_URL}/get-file/${employee?.photo})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>) : (
                    <div
                      className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-1'
                      style={{ width: 40, height: 40 }}
                    >
                      <div className='mt-1'><BsClipboardData color='#ffffff' size={25} />
                      </div>

                    </div>
                  )
                }

                <div className='text-end ms-5'></div>
              </div>
              <div className='card-body mt-0 p-3'>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>ФИО</span>
                      <span className='text-sm'>{employee?.name}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Должность</span>
                      <span className='text-sm'>{employee?.role}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Дата рождения</span>
                      <span className='text-sm'>{employee?.date_of_birth}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Телефон</span>
                      <span className='text-sm'>{employee?.number}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Логин</span>
                      <span className='text-sm'>{employee?.login}</span>
                    </div>
                    {
                      access ? (<div className='d-flex align-items-center justify-content-between flex-wrap'>
                        <span className='text-sm text-white'>Хеш пароля</span>
                        <span className='text-sm'>{employee?.password_hash}</span>
                      </div>) : ''
                    }
                  </div>
                  <div className='col-12 col-md-6'>
                    {
                      access ? (<div className='d-flex align-items-center justify-content-between flex-wrap'>
                        <span className='text-sm text-white'>Токен</span>
                        <span className='text-sm'>{employee?.token}</span>
                      </div>) : ''
                    }
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Телеграм</span>
                      <span className='text-sm'>{employee?.telegram}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Подключен</span>
                      <span className='text-sm'>{employee?.connected}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>На смене</span>
                      <span className='text-sm'>{employee?.on_change}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>step</span>
                      <span className='text-sm'>{employee?.step}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className='mb-0'>Смена</p>
        <div className='d-flex align-items-center hideScroll overflowX pt-3 gap-3'>
          {shift.map((item, index) => (
            <div className='scrollCard' key={index}>
              <div className='card cursor mb-3'>
                <div className='card-header p-3 pt-2'>
                  <div
                    className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
                    style={{ width: 40, height: 40 }}
                  >
                    <div className='mt-1'>
                      <BsClipboardData color='#ffffff' size={25} />
                    </div>
                  </div>
                  <div className='text-end ms-5'></div>
                </div>
                <div className='card-body mt-0 p-3'>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Охраник</span>
                    <span className='text-sm'>{employee?.name}</span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Объект</span>
                    <span className='text-sm'>
                      {objects.find((i) => i.id == item.object_id)?.name ||
                        item.object_id}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Дата и время начала</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_start!).toLocaleString()}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Дата и время конца</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_end!).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className='mb-0'>Обходы</p>
        <div className='d-flex align-items-stretch hideScroll overflowX pt-3 gap-3 mb-3'>
          {checking.map((item, index) => (
            <div className='scrollCard' key={index}>
              <div className='card cursor mb-3 h-100'>
                <div className='card-header p-3 pt-2'>
                  <div
                    className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
                    style={{ width: 40, height: 40 }}
                  >
                    <div className='mt-1' onClick={() => openModal2(item)}>
                      <BsMap color='#ffffff' size={25} />
                    </div>
                  </div>
                  <div className='text-end ms-5'></div>
                </div>
                <div className='card-body mt-0 p-3'>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Охраник</span>
                    <span className='text-sm'>{employee?.name}</span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Объект</span>
                    <span className='text-sm'>
                      {objects.find((i) => i.id == item.object_id)?.name ||
                        item.object_id}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Дата и время начала</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_start).toLocaleString('ru-RU')}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Дата и время конца</span>
                    <span className='text-sm'>
                      {new Date(item.datetime_end).toLocaleString('ru-RU')}
                    </span>
                  </div>
                  {item.info &&
                    item.info[0] === '[' &&
                    JSON.parse(item.info)?.map((i: any, index: number) => (
                      <div
                        className='d-flex align-items-center justify-content-between flex-wrap'
                        key={index}
                      >
                        <span className='text-sm'>{i.title}</span>
                        <span className='text-sm'>
                          {typeof i.value === 'boolean'
                            ? i.value === true
                              ? 'Да'
                              : 'Нет'
                            : i.value}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className='mb-0'>События</p>
        <div className='d-flex align-items-stretch hideScroll overflowX pt-3 gap-3'>
          {events.map((item, index) => (
            <div className='scrollCard' key={index}>
              <CardEvent item={item} />
              {/* <div className='card cursor mb-3'>
                <div className='card-header p-3 pt-2'>
                  <div
                    className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
                    style={{ width: 40, height: 40 }}
                  >
                    <div className='mt-1'>
                      <BsClipboardData color='#ffffff' size={25} />
                    </div>
                  </div>
                  <div className='text-end ms-5'></div>
                </div>
                <div className='card-body mt-0 p-3'>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Охраник</span>
                    <span className='text-sm'>{employee?.name}</span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Объект</span>
                    <span className='text-sm'>
                      {objects.find((i) => i.id == item.object_id)?.name ||
                        item.object_id}
                    </span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Событие</span>
                    <span className='text-sm'>{item.event}</span>
                  </div>
                  <div className='d-flex align-items-center justify-content-between flex-wrap'>
                    <span className='text-sm'>Дата и время</span>
                    <span className='text-sm'>
                      {new Date(item.create_at!).toLocaleString('ru-RU')}
                    </span>
                  </div>
                  <div className='mt-1'>
                    {item.photo && JSON.parse(item.photo)?.length > 0 ? (
                      <div className='d-flex align-items-center hideScroll overflowX image-container'>
                        {JSON.parse(item.photo).map(
                          (photo: any, index: number) => (
                            <img
                              key={index}
                              src={`${SERVER_URL}/get-file/${photo}`}
                              alt='preview'
                              className='w-100 card-image'
                            />
                          )
                        )}
                      </div>
                    ) : (
                      <img
                        src='https://whey.kz/wp-content/uploads/2020/11/placeholder.png'
                        alt='preview'
                        className='w-100'
                      />
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          ))}
        </div>
      </div>
      <Modal
        open={open}
        closeModal={closeModal}
        children={
          <ModalEmployee mode='edit' handleClose={closeModal} item={employee} />
        }
        width={40}
        translateX={70}
      />
      <Modal
        open={open2}
        closeModal={closeModal2}
        children={
          <div>
            <Maps coords={coords} />
          </div>
        }
      />
    </div >
  );
};

export default EmployeeViewPage;
