import { createBrowserRouter, Navigate } from 'react-router-dom';
import CheckingPage from '../pages/CheckingPage/CheckingPage';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import EmployeePage from '../pages/EmployeePage/EmployeePage';
import EmployeeViewPage from '../pages/EmployeeViewPage/EmployeeViewPage';
import EventPage from '../pages/EventPage/EventPage';
import HomePage from '../pages/HomePage/HomePage';
import ObjectPage from '../pages/ObjectPage/ObjectPage';
import ProfilePage from '../pages/ProfilePage/ProfilePage';
import RolePage from '../pages/RolePage/RolePage';
import ShiftPage from '../pages/ShiftPage/ShiftPage';
import ShiftViewPage from '../pages/ShiftViewPage/ShiftViewPage';
import SignInPage from '../pages/SignInPage/SignInPage';
import SignUpPage from '../pages/SignUpPage/SignUpPage';
import UserPage from '../pages/UserPage/UserPage';
import UserViewPage from '../pages/UserViewPage/UserViewPage';
import { resetCheckingsRequested } from '../store/slices/checkingSlice/checkingSlice';
import { resetEmployeesRequested } from '../store/slices/employeeSlice/employeeSlice';
import { resetEventsRequested } from '../store/slices/eventSlice/eventSlice';
import { resetObjectsRequested } from '../store/slices/objectSlice/objectSlice';
import { resetShiftsRequested } from '../store/slices/shiftSlice/shiftSlice';
import { store } from '../store/store';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import PublicRoute from './PublicRoute/PublicRoute';

export const roles = {
  director: 'Директор',
  headOfSecurity: 'Руководитель ЧОП',
  client: 'Заказчик'
}

export const router = createBrowserRouter([
  {
    path: '/auth/signin',
    element: (
      <PublicRoute>
        <SignInPage />
      </PublicRoute>
    ),
  },
  {
    path: '/auth/signup',
    element: (
      <PublicRoute>
        <SignUpPage />
      </PublicRoute>
    ),
  },
  {
    path: '/',
    element: (
      <PrivateRoute roles={Object.values(roles)} >
        <HomePage />
      </PrivateRoute >
    ),
  },
  {
    path: '/checking',
    element: (
      <PrivateRoute roles={Object.values(roles)} >
        <CheckingPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/shift',
    element: (
      <PrivateRoute roles={Object.values(roles)} >
        <ShiftPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/event',
    element: (
      <PrivateRoute roles={Object.values(roles)} >
        <EventPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/employee',
    element: (
      <PrivateRoute roles={Object.values(roles)} >
        <EmployeePage />
      </PrivateRoute>
    ),
  },
  // {
  //   path: '/role',
  //   element: (
  //     <PrivateRoute>
  //       <RolePage />
  //     </PrivateRoute>
  //   ),
  // },
  {
    path: '/user',
    element: (
      <PrivateRoute roles={[roles.director, roles.headOfSecurity]} >
        <UserPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <PrivateRoute roles={Object.values(roles)} >
        <DashboardPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/profile',
    element: (
      <PrivateRoute roles={Object.values(roles)} >
        <ProfilePage />
      </PrivateRoute>
    ),
  },
  {
    path: '/object/:id',
    element: (
      <PrivateRoute roles={Object.values(roles)} >
        <ObjectPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/employee/:id',
    element: (
      <PrivateRoute roles={Object.values(roles)} >
        <EmployeeViewPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/user/:id',
    element: (
      <PrivateRoute roles={[roles.director, roles.headOfSecurity]}>
        <UserViewPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/shift/:id',
    element: (
      <PrivateRoute roles={Object.values(roles)}  >
        <ShiftViewPage />
      </PrivateRoute>
    ),
  },
  {
    path: '*',
    element: (
      <Navigate to={'/'} />
    ),
  }
]);

router.subscribe((state) => {
  store.dispatch(resetObjectsRequested());
  store.dispatch(resetCheckingsRequested());
  store.dispatch(resetEmployeesRequested());
  store.dispatch(resetShiftsRequested());
  store.dispatch(resetEventsRequested());
});
