import React, { useEffect, useRef } from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import './assets/css/material-dashboard-pro.css';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { roles, router } from './navigation/navigation';
import { YMaps } from 'react-yandex-maps';
import { useAppDispatch, useAppSelector } from './store/store';
import { getUser } from './store/slices/userSlice/userSlice';


function App() {
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    const id = localStorage.getItem('userId') as string;
    dispatch(getUser(id));
  }, []);
  // useEffect(() => {
  //   if (user) {
  //     if (user.role === roles.client) {
  //       if (!objects) {
  //         dispatch(getObjects({ limit: 20, offset: 0, query_and: [{ input: 'client_id', value: user.id }] }));
  //         objectsLoaded.current = true;
  //       } else if (!employees) {
  //         dispatch(getEmployees({ limit: 20, offset: 0 }));
  //       }

  //     } else {
  //       dispatch(getObjects({ limit: 20, offset: 0 }));
  //       dispatch(getEmployees({ limit: 20, offset: 0 }));
  //       objectsLoaded.current = true;
  //     }
  //   }
  // }, [user, objects, employees])

  return (
    <YMaps>
      <div className='g-sidenav-show bg-gray-200 dark-version fullHeight'>
        <RouterProvider router={router} />
      </div>
    </YMaps>
  );
}

export default App;
