import React, { useEffect, useState } from 'react';
import { BsClipboardData } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import ModalUser from '../../components/ModalUser/ModalUser';
import Sidebar from '../../components/Sidebar/Sidebar';
import { getUserInfo } from '../../store/slices/userSlice/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';

interface IUserViewPageProp {
  propsId?: number
}
const UserViewPage = ({ propsId }: IUserViewPageProp) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.currentUser);
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  useEffect(() => {
    dispatch(getUserInfo(propsId || id!));
  }, []);

  return (
    <div>
      <Sidebar />
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <h6 className='m-0'>Информация</h6>
          <button className='btn btn-info btn-sm m-0' onClick={openModal}>
            Изменить
          </button>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='card cursor mb-3'>
              <div className='card-header p-3 pt-2'>
                <div
                  className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-1'
                  style={{ width: 40, height: 40 }}
                >
                  <div className='mt-1'>
                    <BsClipboardData color='#ffffff' size={25} />
                  </div>
                </div>
                <div className='text-end ms-5'></div>
              </div>
              <div className='card-body mt-0 p-3'>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Логин</span>
                      <span className='text-sm'>{user?.login}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Роль</span>
                      <span className='text-sm'>{user?.role}</span>
                    </div>
                    {/* <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Должность</span>
                      <span className='text-sm'>{user?.role}</span>
                    </div> */}
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>ФИО</span>
                      <span className='text-sm'>{user?.name}</span>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Онлайн</span>
                      <span className='text-sm'></span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Пароль</span>
                      <span className='text-sm'>{user?.password_hash}</span>
                    </div>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Телефон</span>
                      <span className='text-sm'>{user?.phone}</span>
                    </div>
                    {/* <div className='d-flex align-items-center justify-content-between flex-wrap'>
                      <span className='text-sm text-white'>Доступ</span>
                      <span className='text-sm'>{user?.access}</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        closeModal={closeModal}
        children={
          <ModalUser mode='edit' handleClose={closeModal} item={user} />
        }
        width={40}
        translateX={70}
      />
    </div>
  );
};

export default UserViewPage;
