import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import ReactModal from 'react-modal';

interface IModal {
  open: boolean;
  closeModal: () => void;
  children: JSX.Element;
  width?: number;
  translateX?: number;
  height?: number;
  translateY?: number;
}

const Modal: React.FC<IModal> = ({
  open,
  closeModal,
  children,
  width = 0,
  translateX = 0,
  height = 0,
  translateY = 0,
}) => {
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);

  const handleResize = () => {
    setWidthWindow(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={closeModal}
      style={{
        content: {
          background: '#1a2035',
          border: 'none',
          borderRadius: 20,
          width: widthWindow < 770 ? 'auto' : width ? width + '%' : '70%',
          transform:
            widthWindow < 770
              ? `translate(0,${translateY})`
              : translateX
              ? `translate(${translateX}%, ${translateY}%)`
              : `translate(20%, ${translateY}%)`,
          height: widthWindow < 770 ? 'auto' : height ? height + '%' : 'auto',
        },
        overlay: {
          zIndex: 10000,
          background: 'rgba(0,0,0,0.5)',
        },
      }}
    >
      <div
        style={{ maxHeight: '100%', overflowY: 'scroll', height: '100%' }}
        className='hideScroll'
      >
        <div
          className='position-fixed end-3 top-3 hideScroll cursor'
          style={{ height: 30 }}
          onClick={closeModal}
        >
          <MdClose size={25} color={'#fff'} />
        </div>
        <div
          className={'dark-version'}
          style={{ marginTop: 30, height: '100%' }}
        >
          {children}
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
