import React, { useEffect, useState } from 'react';
import CardUser from '../../components/CardUser/CardUser';
import Modal from '../../components/Modal/Modal';
import ModalUser from '../../components/ModalUser/ModalUser';
import Pagination from '../../components/Pagination/Pagination';
import Sidebar from '../../components/Sidebar/Sidebar';
import { getUsers, IUserConf } from '../../store/slices/userSlice/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';

const UserPage = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [offset, setOffset] = useState(0);
  const [currentItem, setCurrentItem] = useState<IUserConf | null>(null);
  const data = useAppSelector((state) => state.user.data);
  const total = useAppSelector((state) => state.user.total);
  const dispatch = useAppDispatch();
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const openModal2 = (item: IUserConf) => {
    setCurrentItem(item);
    setOpen2(true);
  };
  const closeModal2 = () => setOpen2(false);

  useEffect(() => {
    dispatch(getUsers({ limit: 20, offset }));
  }, []);

  useEffect(() => {
    dispatch(getUsers({ limit: 20, offset }));
  }, [offset]);


  return (
    <div>
      <Sidebar />
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <button className='btn btn-info btn-sm m-0' onClick={openModal}>
            Добавить
          </button>
        </div>
        <div className='row'>
          {data.map((item: IUserConf, index: number) => (
            <div className='col-12 col-md-4' key={index}>
              <CardUser handleOpen={openModal2} item={item} />
            </div>
          ))}
        </div>
        <Pagination
          offset={offset}
          total={total}
          handleBack={() => {
            if (offset <= 0) return;
            setOffset(offset - 20);
          }}
          handleNext={() => {
            if (offset > total) return;
            setOffset(offset + 20);
          }}
        />
        <Modal
          open={open}
          closeModal={closeModal}
          children={<ModalUser mode='create' handleClose={closeModal} />}
          width={40}
          translateX={70}
        />
        <Modal
          open={open2}
          closeModal={closeModal2}
          children={
            <ModalUser
              mode='edit'
              handleClose={closeModal2}
              item={currentItem!}
            />
          }
          width={40}
          translateX={70}
        />
      </div>
    </div>
  );
};

export default UserPage;
