import React, { useState, useEffect } from 'react';
import { BsClipboardData } from 'react-icons/bs';
import CardEvent from '../../components/CardEvent/CardEvent';
import Maps from '../../components/Maps/Maps';
import Modal from '../../components/Modal/Modal';
import Scheduler from '../../components/Scheduler/Scheduler';
import Sidebar from '../../components/Sidebar/Sidebar';
import { SERVER_URL } from '../../helpers/const';
import { roles } from '../../navigation/navigation';
import {
  getCheckings,
  ICheckingConf,
} from '../../store/slices/checkingSlice/checkingSlice';
import { getEmployees } from '../../store/slices/employeeSlice/employeeSlice';
import { getEvents } from '../../store/slices/eventSlice/eventSlice';
import { getObjects } from '../../store/slices/objectSlice/objectSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import styles from './DashboardPage.module.css';

const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const events = useAppSelector((state) => state.event.data);
  const checkings = useAppSelector((state) => state.checking.data);
  const user = useAppSelector((state) => state.user.user);
  const objects = useAppSelector((state) => state.object.data);
  const objectsRequested = useAppSelector((state) => state.object.isRequested);
  const employeesRequested = useAppSelector((state) => state.employee.isRequested);
  const eventsRequested = useAppSelector((state) => state.event.isRequested);
  const [open, setOpen] = useState(false);
  const [coords, setCoords] = useState([]);

  const openMap = (id: string | number) => {
    const item = checkings.find((item) => item.id == id);
    setCoords(item!.coords ? JSON.parse(item!.coords) : []);
    setOpen(true);
  };
  const closeMap = () => setOpen(false);

  // useEffect(() => {
  //   dispatch(getEvents({ limit: 10, offset: 0 }));
  //   dispatch(getCheckings({ limit: 10, offset: 0 }));
  // }, []);

  useEffect(() => {
    if (!user) return;
    if (user.role === roles.client) {
      if (!objectsRequested) {
        dispatch(getObjects({ limit: 0, offset: 0, query_and: [{ input: 'client_id', value: user.id }] }));

      } else if (!eventsRequested) {
        const query = objects.map(object => ({ input: 'object_id', value: object.id }))

        dispatch(getEvents({ limit: 10, offset: 0, query }))
        dispatch(getCheckings({ limit: 10, offset: 0, query }));


        const securityIds: any = objects.map(object => {
          return JSON.parse(object.ohraniki).map((security: any) => security.value)
        });
        dispatch(getEmployees({ limit: 0, offset: 0, query: securityIds.flat().map((id: any) => ({ input: 'id', value: id })) }));

      }

    } else if (!objectsRequested) {
      dispatch(getObjects({ limit: 0, offset: 0 }));
      dispatch(getEvents({ limit: 10, offset: 0 }));
      dispatch(getCheckings({ limit: 10, offset: 0 }));
      dispatch(getEmployees({ limit: 0, offset: 0 }));
    }
  }, [objects, user, objectsRequested, eventsRequested]);


  return (
    <div>
      <Sidebar />
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        <div className='row'>
          <div className='col-12'>
            <p>Последние события</p>
            <div className={'row'}>
              {events.map((item, index) => (
                <div className='col-12 mb-3' key={index}>
                  <CardEvent fullWidth item={item} />
                </div>
                // <div className='col-12 col-md-6' key={index}>
                //   <div className='card cursor mb-3'>
                //     <div className='card-header p-3 pt-2'>
                //       <div
                //         className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
                //         style={{ width: 40, height: 40 }}
                //       >
                //         <div className='mt-1'>
                //           <BsClipboardData color='#ffffff' size={25} />
                //         </div>
                //       </div>
                //       <div className='text-end ms-5'></div>
                //     </div>
                //     <div className='card-body mt-0 p-3'>
                //       <div className='d-flex align-items-center justify-content-between flex-wrap'>
                //         <span className='text-sm'>Охраник</span>
                //         <span className='text-sm'>
                //           {item.guard_id || item.guard_id}
                //         </span>
                //       </div>
                //       <div className='d-flex align-items-center justify-content-between flex-wrap'>
                //         <span className='text-sm'>Объект</span>
                //         <span className='text-sm'>{item.object_id}</span>
                //       </div>
                //       <div className='d-flex align-items-center justify-content-between flex-wrap'>
                //         <span className='text-sm'>Событие</span>
                //         <span className='text-sm'>{item.event}</span>
                //       </div>
                //       <div className='d-flex align-items-center justify-content-between flex-wrap'>
                //         <span className='text-sm'>Дата и время</span>
                //         <span className='text-sm'>
                //           {new Date(item.create_at!).toLocaleString('ru-RU')}
                //         </span>
                //       </div>
                //       <div className='mt-1'>
                //         {item.photo && JSON.parse(item.photo)?.length > 0 ? (
                //           <img
                //             src={`${SERVER_URL}/get-file/${item.photo}`}
                //             alt='preview'
                //             className='w-100'
                //           />
                //         ) : (
                //           <img
                //             src='https://whey.kz/wp-content/uploads/2020/11/placeholder.png'
                //             alt='preview'
                //             className='w-100'
                //           />
                //         )}
                //       </div>
                //     </div>
                //   </div>
                // </div>
              ))}
            </div>
          </div>
          {/* <div className='col-12 col-md-5'>
            <p>График обходов</p>
            <div
              className={'hideScroll overflowY ' + styles.schedulerContainer}
            >
              <Scheduler
                openMap={openMap}
                data={checkings.map((item: ICheckingConf) => ({
                  startDate: item.datetime_start,
                  endDate: item.datetime_end,
                  title: 'Обход',
                  id: item.id,
                }))}
              />
            </div>
          </div> */}
        </div>
        <Modal
          open={open}
          closeModal={closeMap}
          children={
            <div className=''>
              <Maps coords={coords} />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default DashboardPage;
