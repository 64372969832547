import { SERVER_URL } from './../../helpers/const';
interface IEvent {
  id?: number;
  create_at?: string;
  event?: string;
  guard_id?: number;
  info?: string;
  info_subject?: string;
  object_id?: number;
  photo?: string;
  company_id?: number;
}

export const EventAPI = {
  create: async (body: IEvent) => {
    const response = await fetch(`${SERVER_URL}/event/create`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  update: async (body: IEvent) => {
    const response = await fetch(`${SERVER_URL}/event/update`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  delete: async (body: IEvent) => {
    const response = await fetch(`${SERVER_URL}/event/delete`, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  get: async (id: string | number) => {
    const response = await fetch(`${SERVER_URL}/event/get/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  getList: async (
    limit: number = 0,
    offset: number = 0,
    query_and: any = null,
     query: any = null
  ) => {
    let body = JSON.stringify({ limit, offset });

    if (query && query_and) {
      body = JSON.stringify({ limit, offset, query_and, query });
    } else if (query_and) {
      body = JSON.stringify({ limit, offset, query_and });
    } else if (query) {
      body = JSON.stringify({ limit, offset, query });
    }

    const response = await fetch(`${SERVER_URL}/event/get-list`, {
      method: 'POST',
      body,
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
};
