import React, { useState, useEffect } from 'react';
import SidebarItem from '../SidebarItem/SidebarItem';
import { useNavigate } from 'react-router-dom';
import { IoMdWallet } from 'react-icons/io';
import { MdClose, MdLogout, MdMenu } from 'react-icons/md';
import { BsBank } from 'react-icons/bs';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { logout } from '../../store/slices/userSlice/userSlice';
import { roles } from '../../navigation/navigation';

const Sidebar = () => {
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [active, setActive] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const toggleActive = () => setActive(!active);
  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <aside
        className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark d-flex flex-column align-items-stretch justify-content-between ${active && 'active'
          } hideScroll`}
        id='sidenav-main'
      >
        <div className='position-relative'>
          <ul className='navbar-nav py-2 hideScroll'>
            {user && user.id ? (
              <li className='nav-item mb-2 mt-0 pt-0'>
                <div
                  className='nav-link text-white border-radius-xl cursor'
                  onClick={handleLogout} 
                >
                  {user.avatar_url ? (
                    <img
                      src={user.avatar_url}
                      className='avatar'
                      alt='avatar'
                    />
                  ) : (
                    <div className='avatar dark-version'></div>
                  )}
                  <div>
                    <div>
                      <span
                        className='nav-link-text ms-2 ps-1 cursor'
                        onClick={handleLogout} 
                      >
                        {user.name}
                      </span>
                    </div>
                    <div>
                      <span onClick={handleLogout} className='ms-2 ps-1'>
                        <MdLogout size={14} style={{ marginBottom: 3 }} />
                      </span>
                    </div>
                  </div>
                </div>
                <hr className='horizontal my-0 light' />
              </li>
            ) : (
              ''
            )}
            <SidebarItem to='/dashboard' name='Рабочий стол' />
            <SidebarItem to='/' name='Объекты' />
            <SidebarItem to='/checking' name='Обходы' />
            <SidebarItem to='/event' name='События' />
            <SidebarItem to='/shift' name='Смена' />
            {/* <p className='px-3 py-0 my-0 mb-2'>Справочники</p> */}
            <SidebarItem to='/employee' name='Охранники' />
            {[roles.director, roles.headOfSecurity].includes(user.role) ? (
              <>

                <p className='px-3 py-0 my-0 mb-2'>Настройки</p>
                <SidebarItem to='/user' name='Пользователи' /> </>
            ) : false}
            {/* <SidebarItem to='/role' name='Роли' /> */}
          </ul>
        </div>
      </aside>
      {width < 1200 && (
        <div
          className='menu-burger card p-1 position-fixed start-0 cursor'
          style={{ zIndex: 10000, top: 60 }}
          onClick={toggleActive}
        >
          {active ? (
            <MdClose size={20} color='#fff' />
          ) : (
            <MdMenu size={20} color='#fff' />
          )}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
