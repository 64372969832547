import { SERVER_URL } from './../../helpers/const';
interface IUser {
  id?: number;
  password_hash?: string;
  avatar_url?: string;
  company_id?: number;
  phone?: string;
  push_token?: string;
  role?: string;
  access?: boolean;
  login?: string;
  role_id?: number;
  name?: string;
  ref_id?: number;
}

export const UserAPI = {
  register: async (login: string, password_hash: string, role: string,) => {
    const response = await fetch(`${SERVER_URL}/user/reg`, {
      method: 'POST',
      body: JSON.stringify({
        login,
        role,
        password_hash,
      }),
    });
    const json = await response.json();
    return json;
  },
  create: async (newUser: IUser) => {
    const response = await fetch(`${SERVER_URL}/user/create`, {
      method: 'POST',
      body: JSON.stringify({ role_id: 2, ...newUser }),

      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });

    const json = await response.json();
    return json;
  },
  login: async (login: string, password_hash: string) => {
    const response = await fetch(`${SERVER_URL}/user/login`, {
      method: 'POST',
      body: JSON.stringify({
        login,
        password_hash,
      }),
    });
    const json = await response.json();
    return json;
  },
  get: async (id: string | number) => {
    const response = await fetch(`${SERVER_URL}/user/get/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  update: async (body: IUser) => {
    const response = await fetch(`${SERVER_URL}/user/update`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  delete: async (body: IUser) => {
    const response = await fetch(`${SERVER_URL}/user/delete`, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  getList: async (limit: number = 0, offset: number = 0) => {
    const response = await fetch(`${SERVER_URL}/user/get-list`, {
      method: 'POST',
      body: JSON.stringify({ limit, offset }),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
};
