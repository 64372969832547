import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  createUser, IUserConf, updateUser
} from '../../store/slices/userSlice/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';
import Input from '../Input/Input';
import Select from '../Select/Select';

interface IModalUser {
  mode: 'edit' | 'create' | 'view';
  item?: IUserConf;
  handleClose: () => void;
}

const ModalUser: React.FC<IModalUser> = ({ mode, handleClose, item }) => {
  const [name, setName] = useState('');
  // const [position, setPosition] = useState('');
  const [date, setDate] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [access, setAccess] = useState(false);
  const [login, setLogin] = useState('');
  const [role, setRole] = useState('');
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.user.user);
  const handleNameChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(e.currentTarget.value);
  };
  const handleRoleChange = (
    value: any
  ) => {
    setRole(value);
    console.log(role)
  };
  const handleDateChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDate(e.currentTarget.value);
  };
  const handlePhoneChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPhone(e.currentTarget.value);
  };
  const handlePasswordChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(e.currentTarget.value);
  };
  const handleAccessChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setAccess(e.currentTarget.checked);
  };
  const handleLoginChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLogin(e.currentTarget.value);
  };
  console.log(item)
  const handleSubmit = () => {
    if (mode === 'edit') {
      dispatch(
        updateUser({
          role: role || 'Заказчик',
          name,
          login,
          access,
          phone,
          id: item?.id,
          ...(password ? { password_hash: password } : {}),
        })
      );
    } else if (mode === 'create') {
      dispatch(
        createUser({
          company_id: user.company_id,
          role: role || 'Заказчик',
          name,
          login,
          access,
          phone,
          password_hash: password,
        })
      );
    }
    handleClose();
  };

  useEffect(() => {
    if (mode === 'edit') {
      setRole(item?.role!);
      setName(item?.name!);
      // setPassword(item?.password_hash!);
      setLogin(item?.login!);
      // setPosition(item?.role!);
      setAccess(item?.access!);
      setPhone(item?.phone!);
    }
  }, [mode]);

  return (
    <div className='px-1 h-100 pb-5'>
      <h6>
        {mode === 'create'
          ? 'Добавить'
          : mode === 'edit'
            ? 'Редактировать'
            : 'Просмотр'}
      </h6>
      <div className='d-flex flex-column justify-content-between h-100'>
        <div>
          <div className='mb-2'>
            <div>
              <Input
                placeholder='Логин'
                value={login}
                handleChange={handleLoginChange}
              />
            </div>
          </div>
          <div className='mb-2'>
            <div>
              <Select
                placeholder='Выберите роль'
                options={['Директор', 'Заказчик', 'Руководитель ЧОП'].map((role) => ({
                  label: role,
                  value: role,
                }))}
                handleChange={handleRoleChange}
                value={role ? { label: role } : null}
                multi={false}
              />
            </div>
          </div>
          <div className='mb-2'>
            <div>
              <Input
                placeholder='ФИО'
                value={name}
                handleChange={handleNameChange}
              />
            </div>
          </div>
          <div className='mb-2'>
            <div>
              <Input
                placeholder='Номер тел'
                value={phone}
                handleChange={handlePhoneChange}
              />
            </div>
          </div>
          <div className='mb-2'>
            <div>
              <Input
                placeholder=''
                value={date}
                handleChange={handleDateChange}
                type='date'
              />
            </div>
          </div>
          <div className='mb-2'>
            <div>
              <Input
                placeholder='Пароль'
                value={password}
                handleChange={handlePasswordChange}
              />
            </div>
          </div>
          {/* <div className='mb-3 d-flex align-items-center'>
            <div>
              <input
                type='checkbox'
                name='access'
                id='access'
                placeholder='Доступ'
                checked={access}
                onChange={handleAccessChange}
              />
            </div>
            <p className='m-0 text-sm ms-2'>Доступ</p>
          </div> */}
        </div>
        <button className='btn btn-info w-100' onClick={handleSubmit}>
          Сохранить
        </button>
      </div>
    </div>
  );
};

export default ModalUser;
