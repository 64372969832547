import React from 'react';

const LoadingPage = () => {
  return (
    <div className='fullHeight d-flex align-items-center justify-content-center'>
      <div
        className='spinner-border text-info'
        role='status'
        title='Загрузка...'
      ></div>
    </div>
  );
};

export default LoadingPage;
