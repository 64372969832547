import React, { useState, SyntheticEvent } from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';
import Input from '../Input/Input';

interface IWorkScheduleForm {
  handleDelete: (time: unknown) => void;
  handleCreate: (time: unknown) => void;
  setItems: (value: any[]) => void;
  items: any[];
}

const WorkScheduleForm: React.FC<IWorkScheduleForm> = ({
  handleDelete,
  handleCreate,
  items,
  setItems,
}) => {
  const [value, setValue] = useState('');
  const handleChange = (e: SyntheticEvent<HTMLInputElement>) =>
    setValue(e.currentTarget.value);

  return (
    <div>
      <div className='d-flex align-items-center'>
        <Input
          value={value}
          handleChange={handleChange}
          type='time'
          placeholder='Время обхода (чч:мм)'
        />
        <div className='text-info cursor ms-2'>
          <MdAdd
            size={25}
            onClick={() => {
              handleCreate(value);
              setValue('');
            }}
          />
        </div>
      </div>
      {items.map((item, index) => (
        <div className='d-flex align-items-center mt-2' key={index}>
          <Input
            value={item.value}
            handleChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setItems(
                items.map((i) =>
                  i.id == item.id
                    ? { ...item, value: e.currentTarget.value }
                    : i
                )
              );
            }}
            placeholder='Время обхода'
            type='time'
          />
          <div
            className='text-danger cursor ms-2'
            onClick={() => handleDelete(item)}
          >
            <MdDelete size={25} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default WorkScheduleForm;
