import React from 'react';
import ReactSelect from 'react-select';

interface ISelect {
  options: any[];
  handleChange?: (i: any) => void;
  value?: any;
  placeholder?: string;
  multi?: boolean;
}

const Select: React.FC<ISelect> = ({
  options,
  handleChange,
  value,
  placeholder = 'Выберите',
  multi = false,
}) => {
  return (
    <ReactSelect
      value={value}
      isMulti={multi}
      defaultValue={value}
      onChange={(e) => {
        if (multi) {
          handleChange!(e);
        } else {
          handleChange!(e.value);
        }
      }}
      options={options}
      closeMenuOnSelect={!multi}
      placeholder={placeholder}
      noOptionsMessage={multi ? () => null : () => 'Ничего нет'}
      styles={{
        control: (base, props) => ({
          ...base,
          background: 'transparent',
          fontSize: 14,
        }),
        singleValue: (base, props) => ({
          ...base,
          color: '#ffffff',
          fontSize: 14,
        }),
        menu: (base, props) => ({
          ...base,
          background: '#202940',
          fontSize: 14,
        }),
      }}
    />
  );
};

export default Select;
