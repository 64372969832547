import React, { useCallback, useEffect, useRef, useState } from 'react';

interface IInput {
  value: string;
  handleChange: (e: any) => void;
  handleEnter?: () => void;
  placeholder: string;
  type?: string;
  autoComplete?: boolean;
  disabled?: boolean;
}

const Input: React.FC<IInput> = ({
  value,
  handleChange,
  handleEnter,
  placeholder,
  type = 'text',
  autoComplete = false,
  disabled = false
}) => {
  const [show, setShow] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    if (inputRef.current && type === 'password') {
      if (show) {
        inputRef.current.type = 'text';
      } else {
        inputRef.current.type = 'password';
      }
    }
  }, [show])





  return (
    <div
      style={{ position: 'relative', borderRadius: '6px', }}
      className={
        (value || type === 'time'
          ? 'input-group input-group-outline is-focused'
          : 'input-group input-group-outline')
        + ((autoComplete && !inputRef.current) ? ' is-focused' : '')}
    >
      <label className='form-label'>{placeholder}</label>
      {type === 'textarea' ? (
        <textarea
          value={value}
          onChange={handleChange}
          className='form-control'
          rows={1}
          autoComplete={autoComplete ? 'true' : "new-password" as string}
        ></textarea>
      ) : (
        <input
          disabled={disabled}
          autoComplete={autoComplete ? 'true' : "new-password" as string}
          style={disabled && value ? { border: 'none' } : {}}
          value={value}
          ref={inputRef}
          onChange={handleChange}
          type={type}
          className={'form-control ' + (autoComplete && 'ct-nocomplete')}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && handleEnter) {
              handleEnter();
            }
          }}

        />
      )}
      {
        type === 'password' && (

          <button onClick={() => setShow((prev) => !prev)} style={{ zIndex: 5, backgroundColor: 'transparent', outline: 'none', border: 'none', position: 'absolute', right: '10px', transform: 'translateY(50%)' }} >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              fill="--bs-info"
              className="bi bi-eye-slash-fill"
              viewBox="0 0 16 16"

              style={{ fill: '#1a73e8', display: show ? 'block' : 'none', cursor: 'pointer' }}
            >
              <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
              <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}

              className="bi bi-eye-fill"
              viewBox="0 0 16 16"

              style={{ fill: '#1a73e8', display: show ? 'none' : 'block', cursor: 'pointer' }}
            >
              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
            </svg >
          </button>


        )
      }


    </div >
  );
};

export default Input;
