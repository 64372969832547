import { ShiftAPI } from './../../../api/Shift/ShiftAPI';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export interface ShiftState {
  data: IShiftConf[];
  total: number;
  currentShift?: IShiftConf;
  isRequested: boolean;
}

export interface IShiftConf {
  id?: number;
  datetime_end?: string;
  datetime_start?: string;
  guard_id?: number;
  object_id?: number;
  company_id?: number;
  coords?: string;
}

interface IPagination {
  limit: number;
  offset: number;
  query_and?: any;
  query?: any;
}

const initialState: ShiftState = {
  data: [],
  total: 0,
  currentShift: undefined,
  isRequested: false,
};

export const getShifts = createAsyncThunk(
  'shift/getShifts',
  async ({ limit, offset, query_and, query }: IPagination, thunkAPI) => {
    const response = await ShiftAPI.getList(limit, offset, query_and, query);
    return response;
  }
);

export const createShift = createAsyncThunk(
  'shift/createShift',
  async (data: IShiftConf, thunkAPI) => {
    const response = await ShiftAPI.create(data);
    return response;
  }
);

export const updateShift = createAsyncThunk(
  'shift/updateShift',
  async (data: IShiftConf, thunkAPI) => {
    const response = await ShiftAPI.update(data);
    return response;
  }
);

export const deleteShift = createAsyncThunk(
  'shift/deleteShift',
  async (data: IShiftConf, thunkAPI) => {
    const response = await ShiftAPI.delete(data);
    return { ...response, item: data };
  }
);

export const getShift = createAsyncThunk(
  'shift/getShift',
  async (id: string | number, thunkAPI) => {
    const response = await ShiftAPI.get(id);
    return response;
  }
);

export const shiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    resetShiftsRequested(state) {
      state.isRequested = false;
    }
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getShifts.fulfilled, (state, action) => {
      if (action.payload.status && action.payload.data) {
        state.data = action.payload.data;
        state.total = action.payload.count;
        state.isRequested = true;
      }
    });
    builder.addCase(createShift.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data.push(action.payload.shift);
      }
    });
    builder.addCase(updateShift.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.map((item) =>
          item.id == action.payload.shift.id ? action.payload.shift : item
        );
      }
    });
    builder.addCase(deleteShift.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.data = state.data.filter((i) => i.id != action.payload.item.id);
      }
    });
    builder.addCase(getShift.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.currentShift = action.payload.data;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetShiftsRequested} = shiftSlice.actions;

export default shiftSlice.reducer;
