import React from 'react';
import { AiFillEye } from 'react-icons/ai';
import { BsClipboardData } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { IShiftConf } from '../../store/slices/shiftSlice/shiftSlice';
import { useAppSelector } from '../../store/store';

interface ICardShift {
  item: IShiftConf;
}

const CardShift: React.FC<ICardShift> = ({ item }) => {
  const employees = useAppSelector((state) => state.employee.data);
  const object = useAppSelector((state) => state.object.data);
  const navigate = useNavigate();

  return (
    <div className='card cursor mb-3' onClick={() => {}}>
      <div className='card-header p-3 pt-2'>
        <div
          className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
          style={{ width: 40, height: 40 }}
        >
          <div className='mt-1'>
            <BsClipboardData color='#ffffff' size={25} />
          </div>
        </div>
        <div className='text-end ms-5 text-white'>
          <span title='Просмотр'>
            <AiFillEye
              size={20}
              onClick={(e) => {
                navigate('/shift/' + item.id);
              }}
            />
          </span>
        </div>
      </div>
      <div className='card-body mt-0 p-3'>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Охраник</span>
          <span className='text-sm'>
            {employees.find((i) => i.id == item.guard_id)?.name ||
              item.guard_id}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Объект</span>
          <span className='text-sm'>
            {object.find((i) => i.id == item.object_id)?.name || item.object_id}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Дата и время начала</span>
          <span className='text-sm'>
            {new Date(item.datetime_start!).toLocaleString('ru-RU')}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Дата и время конца</span>
          <span className='text-sm'>
            {new Date(item.datetime_end!).toLocaleString('ru-RU')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardShift;
