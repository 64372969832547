import React from 'react';
import { BsClipboardData, BsMap } from 'react-icons/bs';
import { ICheckingConf } from '../../store/slices/checkingSlice/checkingSlice';
import { useAppSelector } from '../../store/store';

interface ICardChecking {
  openMap: (item: ICheckingConf) => void;
  item: ICheckingConf;
}

const CardCheking: React.FC<ICardChecking> = ({ openMap, item }) => {
  const employees = useAppSelector((state) => state.employee.data);
  const object = useAppSelector((state) => state.object.data);

  return (
    <div className='card cursor h-100' onClick={() => {}}>
      <div className='card-header p-3 pt-2'>
        <div
          className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
          style={{ width: 40, height: 40 }}
          onClick={() => openMap(item)}
        >
          <div className='mt-1'>
            <BsMap color='#ffffff' size={25} />
          </div>
        </div>
        <div className='text-end ms-5'></div>
      </div>
      <div className='card-body mt-0 p-3'>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Охраник</span>
          <span className='text-sm'>
            {employees.find((i) => i.id == item.guard_id)?.name ||
              item.guard_id}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Объект</span>
          <span className='text-sm'>
            {object.find((i) => i.id == item.object_id)?.name || item.object_id}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Дата и время начала</span>
          <span className='text-sm'>
            {new Date(item.datetime_start).toLocaleString('ru-RU')}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Дата и время конца</span>
          <span className='text-sm'>
            {new Date(item.datetime_end).toLocaleString('ru-RU')}
          </span>
        </div>
        {item.info &&
          item.info[0] === '[' &&
          JSON.parse(item.info)?.map((i: any, index: number) => (
            <div
              className='d-flex align-items-center justify-content-between flex-wrap'
              key={index}
            >
              <span className='text-sm'>{i.title}</span>
              <span className='text-sm'>
                {typeof i.value === 'boolean'
                  ? i.value === true
                    ? 'Да'
                    : 'Нет'
                  : i.value}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CardCheking;
