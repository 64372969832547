import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  MdList,
  MdOutlineAssignmentTurnedIn,
  MdTravelExplore,
} from 'react-icons/md';
import {
  AiOutlineBarChart,
  AiOutlineGlobal,
  AiOutlineTeam,
  AiOutlineUser,
  AiFillStar,
} from 'react-icons/ai';
import { BsCalendarEvent, BsFillPuzzleFill } from 'react-icons/bs';

interface ISidebarItem {
  name: string;
  to: string;
}

const SidebarItem: React.FC<ISidebarItem> = ({ name, to }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const icon = () => {
    switch (to) {
      case '/dashboard':
        return <AiOutlineBarChart color='#ffffff' size={25} />;
      case '/':
        return <AiOutlineGlobal color='#ffffff' size={25} />;
      case '/checking':
        return <MdTravelExplore color='#ffffff' size={25} />;
      case '/event':
        return <BsCalendarEvent color='#ffffff' size={25} />;
      case '/shift':
        return <MdOutlineAssignmentTurnedIn color='#ffffff' size={25} />;
      case '/employee':
        return <AiOutlineTeam color='#ffffff' size={25} />;
      case '/user':
        return <AiOutlineUser color='#ffffff' size={25} />;
      case '/role':
        return <AiFillStar color='#ffffff' size={25} />;
      default:
        return <MdList color='#ffffff' size={25} />;
    }
  };

  return (
    <li className='nav-item d-flex align-items-center cursor'>
      <div
        className={`nav-link text-white w-100 position-relative py-1 ${
          pathname === to ? 'dark-version' : ''
        }`}
        onClick={() => {
          navigate(to);
        }}
      >
        <div className='text-white text-center d-flex align-items-center justify-content-center'>
          {icon()}
        </div>
        <span className='nav-link-text ms-2'>{name}</span>
      </div>
    </li>
  );
};

export default SidebarItem;
