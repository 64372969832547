import React from 'react';
import { BsClipboardData } from 'react-icons/bs';
import { AiFillEye, AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import {
  deleteEmployee,
  IEmployeeConf,
} from '../../store/slices/employeeSlice/employeeSlice';
import { useAppDispatch } from '../../store/store';
import { SERVER_URL } from '../../helpers/const';

interface ICardEmployee {
  handleOpen: (item: IEmployeeConf) => void;
  item: IEmployeeConf;
  access?: boolean;
}

const CardEmployee: React.FC<ICardEmployee> = ({ handleOpen, item, access = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className='card cursor mb-3'>
      <div className='card-header p-3 pt-2'>
        {
          item?.photo ? (<div className="border-radius-xl mt-n3 position-absolute start-2" style={{ width: 40, height: 40, backgroundImage: `url(${SERVER_URL}/get-file/${item?.photo})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>) : (<div
            className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
            style={{ width: 40, height: 40 }}
          >
            <div className='mt-1'>
              <BsClipboardData color='#ffffff' size={25} />
            </div>
          </div>
          )
        }

        <div className='text-end ms-5 text-white'>
          <span
            title='Просмотр'
            onClick={() => {
              navigate('/employee/' + item.id);
            }}
          >
            <AiFillEye size={20} />
          </span>
          {
            access ? (<>
              <span
                title='Изменить'
                className='mx-1'
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpen(item);
                }}
              >
                <AiFillEdit size={20} />
              </span>
              <span title='Удалить'>
                <AiFillDelete
                  size={20}
                  onClick={() => {
                    dispatch(deleteEmployee(item));
                  }}
                />
              </span></>) : ''
          }
        </div>
      </div>
      <div className='card-body mt-0 p-3 pt-0'>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>#</span>
          <span className='text-sm'>{item.id}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>ФИО</span>
          <span className='text-sm'>{item.name}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Должность</span>
          <span className='text-sm'>{item.role}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Дата рождения</span>
          <span className='text-sm'>
            {new Date(item.date_of_birth!).toLocaleString('ru-RU')}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Номер тел</span>
          <span className='text-sm'>{item.number}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Логин</span>
          <span className='text-sm'>{item.login}</span>
        </div>
        {
          access ? (<>
            <div className='d-flex align-items-center justify-content-between flex-wrap'>
              <span className='text-sm'>Пароль</span>
              <span className='text-sm'>{item.password}</span>
            </div>
            <div className='d-flex align-items-center justify-content-between flex-wrap'>
              <span className='text-sm'>Токен</span>
              <span className='text-sm'>{item.token}</span>
            </div></>) : ''
        }
      </div>
    </div >
  );
};

export default CardEmployee;
