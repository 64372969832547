import React from 'react';

interface IPagination {
  handleNext?: () => void;
  handleBack?: () => void;
  total?: number;
  offset?: number;
}

const Pagination: React.FC<IPagination> = ({
  handleBack,
  handleNext,
  total,
  offset,
}) => {
  return (
    <div className='d-flex align-items-center justify-content-center gap-1'>
      <button
        className='btn btn-outline-info btn-sm'
        onClick={handleBack}
        disabled={offset! - 20 < 0}
      >
        Назад
      </button>
      <button
        className='btn btn-outline-info btn-sm'
        onClick={handleNext}
        disabled={offset! + 20 >= total!}
      >
        Вперед
      </button>
    </div>
  );
};

export default Pagination;
