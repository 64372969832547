import React, { useEffect, useState } from 'react';
import CardObject from '../../components/CardObject/CardObject';
import Modal from '../../components/Modal/Modal';
import ModalObject from '../../components/ModalObject/ModalObject';
import Pagination from '../../components/Pagination/Pagination';
import Sidebar from '../../components/Sidebar/Sidebar';
import { roles } from '../../navigation/navigation';
import { getEmployees } from '../../store/slices/employeeSlice/employeeSlice';
import {
  getObjects,
  IObjectConf,
} from '../../store/slices/objectSlice/objectSlice';
import { getUsers } from '../../store/slices/userSlice/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';

const HomePage = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.object.data);
  const total = useAppSelector((state) => state.object.total);
  const user = useAppSelector((state) => state.user.user);
  const objectsRequested = useAppSelector((state) => state.object.isRequested);
  const employeesRequested = useAppSelector((state) => state.employee.isRequested);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [offset, setOffset] = useState(0);
  const [currentItem, setCurrentItem] = useState<IObjectConf | null>(null);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const openModal2 = (item: IObjectConf) => {
    setCurrentItem(item);
    setOpen2(true);
  };
  const closeModal2 = () => setOpen2(false);
  // useEffect(() => {
  //   dispatch(getUsers({ limit: 0, offset: 0 }));
  //   dispatch(getEmployees({ limit: 0, offset: 0 }));
  // }, [])

  useEffect(() => {
    if (user.role === roles.client && objectsRequested) {
      dispatch(getObjects({ limit: 20, offset }))

      // const securityIds: any = data.map(object => {
      //   return JSON.parse(object.ohraniki).map((security: any) => security.value)
      // });
      // dispatch(getEmployees({ limit: 0, offset: 0, query: securityIds.flat().map((id: any) => ({ input: 'id', value: id })) }));

    } else if (user.role !== roles.client) {
      dispatch(getObjects({ limit: 20, offset }))
    }

  }, [offset]);


  useEffect(() => {
    if (!user) return;
    if (user.role === roles.client) {
      if (!objectsRequested) {
        dispatch(getObjects({ limit: 0, offset: 0, query_and: [{ input: 'client_id', value: user.id }] }));
      } else if (!employeesRequested) {
        const securityIds: any = data.map(object => {
          return JSON.parse(object.ohraniki).map((security: any) => security.value)
        });

        dispatch(getEmployees({ limit: 0, offset: 0, query: securityIds.flat().map((id: any) => ({ input: 'id', value: id })) }));
      }

    } else if (!employeesRequested) {
      dispatch(getEmployees({ limit: 0, offset: 0 }));
      dispatch(getUsers({ limit: 0, offset: 0 }));

    }
  }, [data, user, objectsRequested, employeesRequested]);





  const access = [roles.director, roles.headOfSecurity].includes(user?.role);


  return (
    <div>
      <Sidebar />
      <div className='avoidSidebar py-3 pe-3 ps-sm-3'>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          {
            access ? (
              <button className='btn btn-info btn-sm m-0' onClick={openModal}>
                Добавить
              </button>
            ) : ''
          }</div>
        <div className='row'>
          {data.map((item, index) => (
            <div className='col-12 col-md-4' key={index}>
              <CardObject access={access} handleOpen={openModal2} item={item} />
            </div>
          ))}
        </div>
        <Pagination
          total={total}
          offset={offset}
          handleBack={() => {
            if (offset <= 0) return;
            setOffset(offset - 20);
          }}
          handleNext={() => {
            if (offset > total) return;
            setOffset(offset + 20);
          }}
        />
        <Modal
          open={open}
          closeModal={closeModal}
          children={<ModalObject mode='create' handleClose={closeModal} />}
          width={40}
          translateX={70}
        />
        <Modal
          open={open2}
          closeModal={closeModal2}
          children={
            <ModalObject
              mode='edit'
              handleClose={closeModal2}
              item={currentItem!}
            />
          }
          width={40}
          translateX={70}
        />
      </div>
    </div>
  );
};

export default HomePage;
