import React from 'react';
import { BsClipboardData } from 'react-icons/bs';
import { AiFillEye, AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import {
  deleteObject,
  IObjectConf,
} from '../../store/slices/objectSlice/objectSlice';
import { useAppDispatch, useAppSelector } from '../../store/store';

interface ICardObject {
  handleOpen: (item: IObjectConf) => void;
  item: IObjectConf;
  access?: boolean
}

const CardObject: React.FC<ICardObject> = ({ handleOpen, item, access = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const employee = useAppSelector((state) => state.employee.data);
  const users = useAppSelector((state) => state.user.data);

  let periodicity = '';
  if (item.periodicity) {
    periodicity = JSON.parse(item.periodicity).toString();
  }
  return (
    <div className='card cursor mb-3'>
      <div className='card-header p-3 pt-2'>
        <div
          className='icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n3 position-absolute start-2'
          style={{ width: 40, height: 40 }}
        >
          <div className='mt-1'>
            <BsClipboardData color='#ffffff' size={25} />
          </div>
        </div>
        <div className='text-end ms-2 text-white text-sm'>
          <span title='Просмотр'>
            <AiFillEye
              size={20}
              onClick={(e) => {
                navigate('/object/' + item.id);
              }}
            />
          </span>
          {
            access ? (<>
              <span title='Изменить' className='mx-1'>
                <AiFillEdit
                  size={20}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpen(item);
                  }}
                />
              </span>
              <span title='Удалить'>
                <AiFillDelete
                  size={20}
                  onClick={() => {
                    dispatch(deleteObject(item));
                  }}
                />
              </span></>) : ''
          }
        </div>
      </div>
      <div className='card-body mt-0 p-3 pt-0'>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>#</span>
          <span className='text-sm'>{item.id}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Наименование</span>
          <span className='text-sm'>{item.name}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Описание</span>
          <span className='text-sm'>{item.description}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Охраники</span>
          <div className='d-flex flex-column align-items-end'>
            {item.ohraniki[0] === '['
              ? JSON.parse(item.ohraniki).map((i: any, index: number) => (
                <div key={index}>
                  <span className='text-sm'>{i.label}</span>
                </div>
              ))
              : item.ohraniki}
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Начальник охраны</span>
          <span className='text-sm'>
            {users.find((i) => i.id == Number(item.head_of_security))
              ?.name || item.head_of_security}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Переодичность обхода</span>
          <span className='text-sm'>{periodicity}</span>
        </div>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <span className='text-sm'>Длительность</span>
          <span className='text-sm'>{item.dlitelnost}</span>
        </div>
      </div>
    </div>
  );
};

export default CardObject;
