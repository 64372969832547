import { SERVER_URL } from './../../helpers/const';
interface IEmployee {
  id?: number;
  password?: string;
  step?: number;
  token?: string;
  avatar_url?: string;
  company_id?: number;
  login?: string;
  name?: string;
  on_change?: boolean;
  role?: string;
  telegram?: number;
  connected?: boolean;
  date_of_birth?: string;
  photo?: string;
  number?: string;
  password_hash?: string;
}

export const EmployeeAPI = {
  create: async (body: IEmployee) => {
    const response = await fetch(`${SERVER_URL}/employee/create`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  update: async (body: IEmployee) => {
    const response = await fetch(`${SERVER_URL}/employee/update`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });

    const json = await response.json();
    return json;
  },
  delete: async (body: IEmployee) => {
    const response = await fetch(`${SERVER_URL}/employee/delete`, {
      method: 'DELETE',
      body: JSON.stringify(body),
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  get: async (id: string | number) => {
    const response = await fetch(`${SERVER_URL}/employee/get/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
  getList: async (limit: number = 0, offset: number = 0, query_and: any = null, query: any = null) => {
    let body = JSON.stringify({ limit, offset });

    if (query && query_and) {
      body = JSON.stringify({ limit, offset, query_and, query });
    } else if (query_and) {
      body = JSON.stringify({ limit, offset, query_and });
    } else if (query) {
      body = JSON.stringify({ limit, offset, query });
    }

    const response = await fetch(`${SERVER_URL}/employee/get-list`, {
      method: 'POST',
      body,
      headers: {
        Authorization: localStorage.getItem('token') as string,
      },
    });
    const json = await response.json();
    return json;
  },
};
